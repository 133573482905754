import { call, put } from "redux-saga/effects";
import { WorkgroupActions, UIActions } from "../actions";
import { applyExportOptions, downloadCsvFile } from "../lib/dataExportUtils";
import { WorkgroupService } from "../services/workgroup.service";

export function* getWorkgroupList(action: ReturnType<typeof WorkgroupActions.getList>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(WorkgroupService.getInstance().getWorkgroups, action.payload.filter);
        
        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }
        
        yield put(WorkgroupActions.setList(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(WorkgroupActions.setList(null));
        }
        console.log('GET WORKGROUP LIST ERROR', err)
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* createWorkgroup(action: ReturnType<typeof WorkgroupActions.create>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(WorkgroupService.getInstance().createWorkgroup, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        yield put(UIActions.showLoader(false));
        console.log('CREATE WORKGROUP ERROR', err)
        action.payload.error()
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* updateWorkgroup(action: ReturnType<typeof WorkgroupActions.update>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(WorkgroupService.getInstance().updateWorkgroup, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        console.log('UPDATE WORKGROUP ERROR', err)
        action.payload.error()
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* reprocessWorkgroup(action: ReturnType<typeof WorkgroupActions.reprocess>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(WorkgroupService.getInstance().reprocessWorkgroup, action.payload.id);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        console.log('REPROCESS WORKGROUP ERROR', err)
        // action.payload.error()
        if (err instanceof Error) {
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}
