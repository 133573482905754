import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField } from '@material-ui/core';
import { getModalClasses, CommonStyles, getSmallModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactJson from 'react-json-view';
import dataList from '../../constants/dataList';
import { Alert } from '@mui/material';
import { RxActions } from '../../redux/actions';

interface AlertTransactionModalProps {
  rx: any
  preloadedProducts: any[]
  getAlertTransactions: () => void
  getRxExceptionsCount: () => void
  resolveRx: (payload: any) => void
  closeModal: () => void
}

const AlertTransactionModal: React.FC<AlertTransactionModalProps> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getSmallModalStyle());
  const getInitInputs = () => ({
    productId: props.rx?.productId || '',
    shipAddress: props.rx?.shipAddress || '',
    shipCity: props.rx?.shipCity || '',
    shipState: props.rx?.shipState.toUpperCase() || '',
    shipZip: props.rx?.shipZip || '',
    phone: props.rx?.phone || '',
  })

  const [inputs, setInputs] = React.useState<any>(getInitInputs())
  const [formErrors, setFormErrors] = React.useState<any>({})

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [submitError, setSubmitError] = React.useState<string>('')

  const handleCancel = () => {
    props.closeModal();
  }

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value })
    setFormErrors({ ...formErrors, [key]: null })
    setSubmitError('')
  }

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, ' $1').trim()
  }

  const handleSave = () => {
    const errors: any = {}
    for (let [key, value] of Object.entries(inputs)) {
      if (!value) {
        errors[key] = `${formatString(key)} is required`
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      props.getAlertTransactions()
      props.getRxExceptionsCount()
      props.closeModal()
    }

    const onError = () => {
      setIsLoading(false)
      setSubmitError('Failed to save Rx')
    }

    const requestBody = getEditRequestBody()

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError
    }

    props.resolveRx(payload)
  }

  const getEditRequestBody = () => ({
      id: props.rx.id,
      productId: inputs.productId,
      shipAddress: inputs.shipAddress,
      shipCity: inputs.shipCity,
      shipState: inputs.shipState,
      shipZip: inputs.shipZip,
      phone: inputs.phone,
  })

  const renderSupplies = () => {
    return (
      props.rx.rxSupplies?.map((supply: any) => {
        if(props.preloadedProducts.some((product: any) => product.id === supply.productId)){
          return <Alert style={{ width: 400 }} severity="success">{supply.productId}</Alert>
        }
        else{
          return <Alert style={{ width: 400 }} severity="warning">{supply.productId}</Alert>
        }
      })
    )
  }

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <div>
        <h3 id="simple-modal-title">Resolve Rx Exception</h3>
        <Grid container>
          <Grid item xs={12}>
            {props.rx.stateId === 1 &&
              <FormControl className={classes.formControlLarge}>
              <Alert severity="error">This Rx was imported without a Product ID and cannot be resolved. Ask system administrator for assistance.</Alert>
            </FormControl>
            }
            {props.rx.stateId === 2 &&
              <Grid item xs={12}>
                <FormControl className={classes.formControlMedium}>
                  <TextField 
                    variant="outlined" 
                    label="Ship Address" 
                    value={inputs.shipAddress}
                    onChange={(event) => { handleSetInputs('shipAddress', event.target.value) }}
                    error={formErrors.shipAddress ? true : false} 
                    helperText={formErrors.shipAddress || ''} 
                    required 
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField 
                    variant="outlined" 
                    label="Ship City" 
                    value={inputs.shipCity}
                    onChange={(event) => { handleSetInputs('shipCity', event.target.value) }}
                    error={formErrors.shipCity ? true : false} 
                    helperText={formErrors.shipCity || ''} 
                    required 
                  />
                </FormControl>
                <FormControl className={classes.formControlMedium}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={dataList.StateCodes}
                    getOptionLabel={(option: any) => `${option.value}(${option.label})`}
                    value={inputs.shipState ? dataList.StateCodes.find((code: any) => code.label === inputs.shipState) : null}
                    onChange={(event, newValue) => {
                      handleSetInputs('shipState', newValue?.label || null);
                    }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        variant="outlined" 
                        label="Ship State" 
                        error={formErrors.shipState ? true : false} 
                        helperText={formErrors.shipState || ''} 
                        required 
                      />
                    }
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField 
                    variant="outlined" 
                    label="Ship Zip" 
                    value={inputs.shipZip}
                    onChange={(event) => { handleSetInputs('shipZip', event.target.value) }}
                    error={formErrors.shipZip ? true : false} 
                    helperText={formErrors.shipZip || ''} 
                    required 
                  />
                </FormControl>
              </Grid>
            }
            {props.rx.stateId === 3 &&
              <FormControl className={classes.formControlLarge}>
                <Alert  severity="error">The system was unable to identify one or more supplies listed in this Rx. Add the required supplies to the Products page, and then attempt to resolve this error.</Alert>
                <h4>Supplies</h4>
                {renderSupplies()}
              </FormControl>
            }
            {props.rx.stateId === 4 &&
              <FormControl className={classes.formControlLarge}>
                <Alert severity="error">The system was unable to identify the Product ID listed in this Rx. Add the required product to the Products page, and then attempt to resolve this error.</Alert>
                <h4>Product</h4>
                <Alert severity={props.preloadedProducts.some((product: any) => product.id === props.rx?.productId) ? "success" : "warning"}>{props.rx?.productId}</Alert>
              </FormControl>
            }
            {props.rx.stateId === 5 &&
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField 
                    variant="outlined" 
                    label="Phone Number" 
                    value={inputs.phone}
                    onChange={(event) => { handleSetInputs('phone', event.target.value) }}
                    error={formErrors.phone ? true : false} 
                    helperText={formErrors.phone || ''} 
                    required 
                  />
                </FormControl>
              </Grid>
            }
            {props.rx.stateId === 6 &&
              <FormControl className={classes.formControlLarge}>
                <Alert severity="error">The Rx was unable to fit in any pack set designated for this product. Review the dimensions for the product or pack set associated with the product to add/update.</Alert>
              </FormControl>
            }
            {props.rx.stateId === 99 &&
              <FormControl className={classes.formControlLarge}>
                <Alert severity="error">Unable to resolve this error code. Ask system administrator for assistance.</Alert>
              </FormControl>
            }
          </Grid>
          <Grid item xs={12}>
            <h4>Error to Resolve</h4>
            <FormControl className={classes.formControlLarge}>
              <Alert severity="error">{JSON.parse(props.rx.rxState).state}</Alert>
            </FormControl>
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ?
            <Loading message="" />
            :
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  className={classes.searchButton}
                  variant="contained"
                  color="primary"
                >
                  RESOLVE
                </Button>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  className={classes.cancelBtn}
                  variant="contained"
                  color="default"
                >
                  CANCEL
                </Button>
              </FormControl>
            </Grid>}
            <Grid item xs={12}>
              <h4>View Details</h4>
              <div style={{ maxHeight: 400, overflow: 'scroll' }}>
                <ReactJson
                  src={props.rx} 
                  name={false} 
                  displayDataTypes={false} 
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={true}
                />
              </div>
            </Grid>
        </Grid>
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({
  preloadedProducts: state.product.preloadedProducts ? state.product.preloadedProducts.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getRxExceptionsCount: () => dispatch(RxActions.getRxExceptionsCount())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertTransactionModal);
