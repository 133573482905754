import * as React from 'react';
import { Button, Grid, Modal, Tooltip } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import dataList from '../../constants/dataList';
import MUIDataTable from "mui-datatables";
import Loading from '../../components/loading';
import moment from 'moment';
import SearchFilter from '../../components/searchFilter';
import DeleteModal from '../../components/deleteModal';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

interface PrescriptionsProps {
  authUser?: any   
  prescriptions: any[]
  totalPrescriptions: any
  serviceTypes: any[]
  getPrescriptions: (filter: any) => void
  cancelRx: (payload: any) => void
  importReprocess: (payload: any) => void
}

export const PrescriptionsComponent: React.FC<PrescriptionsProps> = (props) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(false);
	const [sortOrder, setSortOrder] = React.useState("shipDate DESC")
	const [filters, setFilters] = React.useState<any>({})
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [modalOpen, setModalOpen] = React.useState(false)
	const [deleteId, setDeleteId] = React.useState(null as any)
	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: 100,
		page: 0
	});

	const renderIcons: any = (tableMeta: any) => {
		return (
			<div style={{ color: 'rgba(0, 0, 0, 0.54)', float: 'right' }}>
				{props.prescriptions[tableMeta.rowIndex].stateId < 300 && props.prescriptions[tableMeta.rowIndex].stateId !== 101 &&
					<Tooltip title="Delete" placement="bottom-start">
						<DeleteIcon
							onClick={(e) => {
								setModalOpen(true)
								setDeleteId(props.prescriptions[tableMeta.rowIndex].id)
							}}
							style={{ marginRight: 10, cursor: 'pointer' }}
						/>
					</Tooltip>
				}
			</div>
		)
	}

	const columnData = [
		{
			label: "Patient Name",
			name: "patient",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => { 
				if(value && value.firstName && value.lastName) return `${value.firstName} ${value.lastName}`
				return ''
			}
		},
		{
			label: "Rx Number",
			name: "rxNumber",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Patient Mrn",
			name: "patientMrn",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Product ID",
			name: "productId",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "NDC",
			name: "ndc",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Doctor NPI",
			name: "doctorNpi",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Therapy Type",
			name: "therapyType",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Dispense QTY",
			name: "dispenseQty",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Invoice",
			name: "invoice",
			change: true,
			viewColumns: true,
			display: false,
		},
		{
			label: "Service Type",
			name: "serviceType",
			change: true,
			viewColumns: true,
			display: true,
		},
		{
			label: "From Date",
			name: "fromDate",
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any, tableMeta: any) => { 
				return moment.utc(value).format('YYYY-MM-DD')
			}
		},
		{
			label: "To Date",
			name: "toDate",
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any, tableMeta: any) => { 
				return moment.utc(value).format('YYYY-MM-DD')
			}
		},
		{
			label: "Ship Date",
			name: "shipDate",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => { 
				return moment.utc(value).format('YYYY-MM-DD')
			}
		},
		{
			label: "MD Name",
			name: "rxDoctor",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => { 
				return value?.name ? value.name : ''
			}
		},
        {
			label: "Ship Address",
			name: "shipAddress",
			change: true,
			viewColumns: true,
			display: true
		},
        {
			label: "Ship Address2",
			name: "shipAddress2",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "Ship State",
			name: "shipState",
			change: true,
			viewColumns: true,
			display: true
		},
        {
			label: "Ship City",
			name: "shipCity",
			change: true,
			viewColumns: true,
			display: true
		},
        {
			label: "Ship Zip",
			name: "shipZip",
			change: true,
			viewColumns: true,
			display: true
		},
        {
			label: "Directions",
			name: "directions",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "Discard After",
			name: "discardAfter",
			change: true,
			viewColumns: true,
			display: false,
			customBodyRender: (value: any, tableMeta: any) => { 
				return moment.utc(value).format('YYYY-MM-DD')
			}
		},
        {
			label: "Doses Allowed",
			name: "dosesAllowed",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "Doses Remaining",
			name: "dosesRemaining",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "Refills Allowed",
			name: "refillsAllowed",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "CPR Order PK",
			name: "cprOrderPk",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "CPR Prescription PK",
			name: "cprPrescriptionPk",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "CPR Drug PK",
			name: "cprDrugPk",
			change: true,
			viewColumns: true,
			display: false
		},
        {
			label: "Status",
			name: "rxState",
			change: true,
			viewColumns: true,
			display: true,
			sort: false,
			customBodyRender: ((value: any) => {
				const rxState = JSON.parse(value)
				return rxState.state
			})
		},
		{
			label: "Group ID",
			name: "rxGroup",
			change: true,
			viewColumns: true,
			display: true,
			sort: false,
			customBodyRender: ((value: any) => {
				const rxGroup = JSON.parse(value)
				return rxGroup.groupId
			})
		},
		{
			label: "Refill Number",
			name: "refillNumber",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "",
			name: "",
			change: false,
			viewColumns: false,
			display: true,
			sort: false,
			customBodyRender: (value: any, tableMeta: any) => { return renderIcons(tableMeta) }
		},
	]

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
				filter: true,
				display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
				change: column.change,
				sort: column.sort ?? true,
				viewColumns: column.viewColumns,
				...(column.customBodyRender) && { customBodyRender: column.customBodyRender },
				}
			}
		})
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterPrescriptions()
		}
		else{
			firstRender.current = false;
		}
	}, [pagination, sortOrder])

	const getFilter = (reset?: boolean) => {
		const filter: any = {
			limit: pagination.limit,
			offset: pagination.offset,
			order: sortOrder ? [sortOrder] : ['id'],
			where: { } //filters ultimately end up here 
		}

		Object.keys(filters).forEach((filterKey: string) => {
			if (['rxNumber', 'productId'].includes(filterKey)) filter.where[filterKey] = filters[filterKey] ? {ilike: `%${filters[filterKey]}%`} : undefined
			else filter.where[filterKey] = filters[filterKey] || undefined
		})

		return filter
	}

	const filterPrescriptions = (reset?: boolean) => {
		props.getPrescriptions({ filter: getFilter(reset) })
	}

	const showLoadingMsg = () => (
		!props.authUser || props.serviceTypes.length === 0
	)

	const handleModalClose = () => {
		setModalOpen(false);
		setDeleteId(null)
	};

	return ( <div>
		{showLoadingMsg() ? <div><Loading message="" /></div> : <div>
		<div className={classes.searchWrap}>
				<Grid container>
					<Grid item xs={'auto'}>
						<SearchFilter
							config={[
								{
									label: 'Rx Number',
									name: 'rxNumber',
									type: 'text'
								},
								{
									label: 'Refill Number',
									name: 'refillNumber',
									type: 'text'
								},
								{
									label: 'Product Id',
									name: 'productId',
									type: 'text'
								},
								{
									label: 'Service Type',
									name: 'serviceType',
									type: 'autoComplete',
									selectOptions: props.serviceTypes.map((serviceType: any) => ({ name: serviceType.carrier, id: serviceType.serviceCode }))
								},
								{
									label: 'Ship Date',
									name: 'shipDate',
									type: 'date'
								},
							]}
							onFilterChange={setFilters}
							filters={filters}
							handleSearch={() => setPageState({...pagination, offset: 0, page: 0})}
						/>
					</Grid>
					<Grid item xs>
						<Button
							className={classes.viewAllBtn}
							variant="contained"
							style={{float: 'right'}}
							color="secondary"
							onClick={() => {
								props.importReprocess({success: setPageState({...pagination, offset: 0, page: 0})})
							}}
						>
							Reprocess
							<CloudDownloadIcon style={{ marginLeft: 10, cursor: 'pointer' }} />
						</Button>
					</Grid>
				</Grid>
			</div>

			<div>
				<MUIDataTable
					data={props.prescriptions}
					columns={getTableColumns()}
					options={{
					rowsPerPage: pagination.limit,
					rowsPerPageOptions: dataList.PageLimitOptions,
					onChangeRowsPerPage: (numberOfRows: number) => {
						setPageState({ offset: 0, limit: numberOfRows, page: 0 });
					},
					filterType: "dropdown",
					responsive: "simple",
					filter: false,
					search: false,
					download: true,
					print: true,
					selectableRows: 'none',
					serverSide: true,
					page: pagination.page,
					count: props.totalPrescriptions,
					onDownload: (buildHead: any, buildBody: any, columns: any) => {
						const filter = getFilter()
						delete filter.limit
						delete filter.offset
						const exportOptions = {
							columns: columns,
							timestampFormat: 'YYYY-MM-DD',
							fileName: 'prescriptions'
						}
						props.getPrescriptions({filter: filter, exportOptions: exportOptions})
						return false
					},
					onColumnSortChange: (changedColumn: string, direction: string) => {
						setSortOrder(`${changedColumn} ${direction.toUpperCase()}`)
					},
					onTableChange: (tableAction: any, tableState: any) => {
						switch (tableAction) {
						case 'changePage':
							if (tableState.page > pagination.page) {
								setPageState({ offset: pagination.offset + pagination.limit, limit: pagination.limit, page: tableState.page });
							}
							else if (tableState.page < pagination.page) {
								setPageState({ offset: pagination.offset - pagination.limit, limit: pagination.limit, page: tableState.page });
							}
							break;
						case 'viewColumnsChange':
							setTableStatePersist(tableState);
							break;
						}
					},
					}}
				/>
			</div>
			<Modal
				open={modalOpen}
				onClose={(e: any, reason: string) => {
					if (reason === 'backdropClick') return
					handleModalClose()
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<>
					<div>
						<DeleteModal
							deleteId={deleteId}
							title={'Delete Prescription'}
							message={`Are you sure you want to delete the Prescription ID ${deleteId}?`}
							onDelete={props.cancelRx}
							onSuccess={filterPrescriptions}
							closeModal={handleModalClose} 
							deleteLabel={'Yes'}
							cancelLabel={'No'}
						/>
					</div>
				</>
			</Modal>
		</div> }
	</div> )
}